import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoginForm} from '../../features/auth/auth-login/login-form.model';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {RegisterForm} from '../../features/auth/auth-registration/register-form.model';
import {Store} from '@ngrx/store';
import {User} from '../../shared/models/user.model';
import * as UserActions from '../../shared/Actions/user.actions';
import {Observable} from 'rxjs';

interface AppState {
  user: User;
}

@Injectable({providedIn: 'root'})

export class AuthService {
  apiUrl = environment.apiUrl;
  isLoginError = false;
  reqHeader = new HttpHeaders({'No-Auth': 'True'});

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState>,
  ) {
  }

  // login method called from loginCompnent
  login(loginForm: LoginForm) {
    loginForm.grant_type = 'password';
    loginForm.client_id = environment.pgc_id;
    loginForm.client_secret = environment.pgc_client_secret;
    loginForm.scope = '';
    return this.http.post<{ access_token: string, expires_in: number }>(`login`, loginForm, {headers: this.reqHeader});
  }

  register(register: RegisterForm) {
    register.email_verify_url = environment.appUrl + '/auth/verify';
    return this.http.post<[RegisterForm]>(`register`, register);
  }

  // check if the request is logged in or not
  isLoggedIn() {
    if (localStorage.getItem('access_token') !== null) {
      const expiresIn = parseInt(localStorage.getItem('expires_in'), 10);
      const currentTime = (new Date()).getTime();
      if (expiresIn > currentTime) {
        return true;
      }
      localStorage.removeItem('access_token');
      localStorage.removeItem('expires_in');
    }
    return false;
  }

  async clientLogin() {
    const reqBody = {
      grant_type: 'client_credentials',
      client_id: environment.pac_id,
      client_secret: environment.pac_client_secret,
      scope: ''
    };
    try {
      const res = await this.http.post<{ access_token: string, expires_in: number }>(`login`, reqBody,
        {headers: this.reqHeader}).toPromise();
      const currentTime = (new Date()).getTime();
      localStorage.setItem('client_access_token', res.access_token);
      localStorage.setItem('client_expires_in', (res.expires_in * 1000 + currentTime).toString());
      return true;
    } catch (err) {
      return false;
    }
  }

  isClient() {
    if (localStorage.getItem('client_access_token') !== null) {
      const expiresIn = parseInt(localStorage.getItem('client_expires_in'), 10);
      const currentTime = (new Date()).getTime();
      if (expiresIn > currentTime) {
        return true;
      }
      localStorage.removeItem('client_acceess_token');
      localStorage.removeItem('client_expiress_in');
    }
    return this.clientLogin();
  }

  isVerified(token: string) {
    return this.http.get<any>(`email/verify?token=${token}`);
  }

  updateState() {
    this.http.get(`self`).subscribe((res: any) => {
      this.store.dispatch(new UserActions.GetUser(res.data));
    });
  }

  logout() {
    this.store.dispatch(new UserActions.DeleteUser());
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('social_login');
    localStorage.removeItem('has_password');
    localStorage.removeItem('client_expires_in');
    localStorage.removeItem('client_access_token');
    this.router.navigate(['/auth/login']);
  }
  // socialLogin
  socialLogin(first_name, last_name, email, social_type, id_token): Observable<any> {
    const body = {first_name, last_name, email, social_type, id_token};
    return this.http.post('social-login', body);
  }
  // emailVerification
  emailVerification(email): Observable<any> {
    return this.http.post('forgot', email);
  }
  // emailVerification
  forgetPassword(data): Observable<any> {
    return this.http.post('reset/forgotten-password', data);
  }
  // Change password
  changePassword(data): Observable<any> {
    return this.http.post('reset-password', data);
  }
  emailTokenVerification(token): Observable<any> {
    return this.http.get<any>(`email-validator?token=${token}`);

  }
}
