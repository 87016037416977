import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ClientGuard } from './core/guards/client.guard';
import { RouteGuardService } from './core/guards/route-guard.service';

const routes: Routes = [
  { path: '', loadChildren: () => import('./features/home/home.module')
  .then(m => m.HomeModule), data: {title: 'Home'} },

  { path: 'auth', loadChildren: () => import('./features/auth/auth.module')
  .then(m => m.AuthModule), data: {title: 'Auth'} },

  { path: 'admin', loadChildren: () => import('./features/admin/admin.module')
  .then(m => m.AdminModule) , canActivate: [AuthGuard, RouteGuardService], data: {title: 'Admin'} },

  { path: 'profile', loadChildren: () => import('./features/profile/profile.module')
  .then(m => m.ProfileModule) , canActivate: [AuthGuard], data: {title: 'Profile', status: 7} },

  { path: 'pre-onboarding', loadChildren: () => import('./features/pre-onboarding/pre-onboarding.module')
  .then(m => m.PreOnboardingModule) , canActivate: [AuthGuard], data: {title: 'Pre Onboarding', status: 8} },

  { path: 'post-onboarding', loadChildren: () => import('./features/post-onboarding/post-onboarding.module')
  .then(m => m.PostOnboardingModule) , canActivate: [AuthGuard], data: {title: 'Post Onboarding', status: 9} },

  { path: 'job-portal', loadChildren: () => import('./features/job-portal/job-portal.module')
  .then(m => m.JobPortalModule) , canActivate: [ClientGuard], data: {title: 'Job Portal'} },

  { path: 'apply', loadChildren: () => import('./features/gt-assessment/gt-assessment.module')
  .then(m => m.GtAssessmentModule) , canActivate: [ClientGuard], data: {title: 'Job Apply'} },

  { path: 'profile-collection', loadChildren: () => import('./features/profile-collection/profile-collection.module')
  .then(m => m.ProfileCollectionModule) , canActivate: [ClientGuard], data: {title: 'Profile Collection'} },

  { path: 'settings', loadChildren: () => import('./features/settings/settings.module')
      .then(m => m.SettingsModule) , canActivate: [AuthGuard], data: {title: 'Profile', status: 7} },

  { path: '**', component: PageNotFoundComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
