import {AuthService} from '@app/core';
import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedreq;
    let accessToken = null;
    if (req.headers.get('No-Auth') === 'True') {
      return next.handle(req.clone());
    }
    if (this.authService.isLoggedIn()) {
      accessToken = 'access_token';
    } else if (this.authService.isClient()) {
      accessToken = 'client_access_token';
    }
    if (accessToken !== null) {
      clonedreq = req.clone({
        headers: new HttpHeaders({Authorization: 'Bearer ' + localStorage.getItem(accessToken)})
      });
      return next.handle(clonedreq).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    }
    this.router.navigate(['/auth/login']);
  }
}

