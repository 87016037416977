import {Injectable, OnInit} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {UserState} from '@app/shared/models/user.model';
import {AuthService} from '@app/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements OnInit, CanActivate {
  role: number;

  constructor(
    private store: Store<UserState>,
    private router: Router,
    private authService: AuthService,
    private  http: HttpClient,
  ) {
  }

  ngOnInit() {
  }

  canActivate(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.http.get('self').subscribe((res: any) => {
        if (res.data.role_id === 1 || res.data.status.id === 38) {
          observer.next(true);
          observer.complete();
        } else {
          observer.next(false);
          this.router.navigate(['/']).then(r => r);
          observer.complete();
        }
      });
    });
  }
}
