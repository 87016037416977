import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { AuthService } from '@app/core';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { LoaderService } from './shared/services/loader.service';
import { Title } from '@angular/platform-browser';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private titleService: Title
  ) { }
  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.authService.updateState();
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.router.routerState.root.snapshot.firstChild.data.title;
        this.titleService.setTitle(title);
      }
    });
  }
}
