import { Action } from '@ngrx/store';

export const GET_USER = '[user]Get';
export const DELETE_USER = '[user]Delete';

export class GetUser implements Action {
    readonly type = GET_USER;

    constructor(public payload: object) {}
}
export class DeleteUser implements Action {
    readonly type = DELETE_USER;
}
export type All = GetUser | DeleteUser;
