import * as UserActions from '../Actions/user.actions';
import { User } from '../models/user.model';

export type Action = UserActions.All;

const defaultState: User = {} as User;

const newState = (state, newData) => {
    return Object.assign({}, state, newData);
};

export function userReducer(state: User = defaultState, action: Action) {
    switch (action.type) {
        case UserActions.GET_USER:
            return newState(state, action.payload);
        case UserActions.DELETE_USER:
            return defaultState;
        default:
            return state;
    }
}
